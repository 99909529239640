[
    {
        "command":"contact",
        "description": "Displays the contact list",
        "responseType":"list",
        "value":[
            "contact@as212022.net",
            "tech@as212022.net",
            "abuse@as212022.net"
        ]
    },
    {
        "command":"peers",
        "description": "Displays the peers list",
        "responseType":"list",
        "value":[
            "<a href='https://dyjix.eu' target='_blank'>Dyjix Association</a> - AS212815",
        ]
    },
    {
        "command":"prefix",
        "description": "Displays the prefix list",
        "responseType":"list",
        "value":[
            "<code>2a10:4646:16::/48</code>"
        ]
    }
]
